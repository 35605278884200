<template>
  <div class="dealers-page">
    <DealersTable />
  </div>
</template>

<script>
import DealersTable from "@/components/dealers-table/DealersTable"

export default {
  name: "Dealers",
  components: {
    DealersTable,
  },
}
</script>

<style scoped></style>
