<template>
  <div class="dealers-table">
    <a-form
      v-if="!onlyList"
      class="ant-advanced-search-form"
      name="advanced_search"
      @submit="handleSearch"
    >
      <div class="filterFields">
        <a-form-item label="Код дилера" class="filterFormItems">
          <a-input
            v-model="filterText"
            style="margin-bottom: 5px"
            placeholder="Код дилера"
          ></a-input>
        </a-form-item>
      </div>
      <div class="buttons">
        <a-button type="primary" @click="filterByName">Фильтр</a-button>
        <a-button @click="clearFilters">Очистить</a-button>
      </div>
    </a-form>

    <div class="content-container">
      <!-- <div class="buttons create-dealer">
        <a-button
          @click="onCreateDealerClick"
          type="primary"
          icon="user-add"
          class="user-add"
        >
          Создать Поставщика
        </a-button>
      </div> -->
      <a-table
        class="ordertable"
        bordered
        :customRow="customRow"
        :columns="columns"
        :expandRowByClick="true"
        :data-source="dealers"
        :scroll="{ x: 1500 }"
        :pagination="{ defaultPageSize: 16, hideOnSinglePage: true }"
        :rowClassName="rowClassName"
      ></a-table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex"

export default {
  name: "DealersTable",
  props: {
    onlyList: {
      type: Boolean,
      default: () => false,
    },
  },
  mounted() {
    this.fetchDealers()
  },
  data() {
    return {
      filterText: "",
      selectedRowKeys: [],
      form: this.$form.createForm(this, { name: "advanced_search" }),
      /* TODO: Удалить */
      /*
      dealers: [
        {
          id: 1,
          name: "Тверь Автосервис",
          address: "",
          phone: "",
          site: "",
        },
        {
          id: 2,
          name: "Хорошо-Моторс",
          address: "г. Москва",
          phone: "84950000000",
          site: "хорошо-моторс.ру",
        },
        {
          id: 3,
          name: "Дилер-Моторс",
          address: "г. Тверь",
          phone: "",
          site: "",
        },
        {
          id: 4,
          name: "Тверь Автосервис",
          address: "г. Тверь",
          phone: "83990000000",
          site: "дилер-моторс.ру",
        },
        {
          id: 5,
          name: "Мотор",
          address: "г. Самара",
          phone: "82220000000",
          site: "мотор.ру",
        },
        {
          id: 6,
          name: "Дилер Барнаул",
          address: "г. Барнаул",
          phone: "86665550000",
          site: "дилербарнаул.ру",
        },
        {
          id: 7,
          name: "Дилер Чита",
          address: "г. Чита",
          phone: "87773330000",
          site: "дилерчита.ру",
        },
        {
          id: 8,
          name: "Дилер Владивосток",
          address: "г. Владивосток",
          phone: "87774440000",
          site: "дилервладивосток.ру",
        },
        {
          id: 9,
          name: "Дилер Екатеринбург",
          address: "г. Екатеринбург",
          phone: "85553330000",
          site: "дилерекатеринбург.ру",
        },
        {
          id: 10,
          name: "Дилер Казань",
          address: "г. Казань",
          phone: "81113330000",
          site: "дилерказань.ру",
        },
      ],
      */
    }
  },
  methods: {
    ...mapActions({
      fetchDealers: "dealers/fetchDealers",
      fetchDealerById: "dealers/fetchDealerById",
    }),
    ...mapMutations({
      setDealers: "dealers/SET_MANUFACTURERS",
    }),
    onCreateDealerClick() {
      this.$router.push("/createdealer")
    },
    onSelectChange(selectedRowKeys) {
      console.log("selectedRowKeys changed: ", selectedRowKeys)
      this.selectedRowKeys = selectedRowKeys
    },
    handleSearch(e) {
      e.preventDefault()
      this.form.validateFields((error, values) => {
        if (error) {
          console.log("error", error)
        } else {
          console.log("Received values of form: ", values)
          this.searchDetails(values)
        }
      })
    },
    handleChange(info) {
      const status = info.file.status
      if (status !== "uploading") {
        console.log(info.file, info.fileList)
      }
      if (status === "done") {
        this.$message.success(`${info.file.name} file uploaded successfully.`)
      } else if (status === "error") {
        this.$message.error(`${info.file.name} file upload failed.`)
      }
    },
    rowClassName(record) {
      if (record.isActive) {
        return "active"
      }
    },
    customRow(record) {
      return {
        on: {
          click: async () => {
            this.$router.push("/dealer/" + record.id)
          },
        },
      }
    },
    clearFilters() {
      this.filterText = ""
      this.fetchDealers()
    },
    filterByName() {
      console.log()
      console.log(this.filterText)
      if (this.filterText !== "") {
        const filterTextArray = this.dealers.filter(
          item =>
            item.dealer.trim().toUpperCase() ===
              this.filterText.trim().toUpperCase() ||
            item.dealer
              .trim()
              .toUpperCase()
              .includes(this.filterText.trim().toUpperCase())
        )
        this.setDealers(filterTextArray)
      } else {
        this.fetchDealers()
      }
    },
  },
  computed: mapGetters({
    dealers: "dealers/dealers",
    columns: "dealers/columns",
  }),
}
</script>

<style scoped lang="scss">
.ordertable {
  ::v-deep .ant-table-tbody > tr {
    cursor: pointer;
  }
}
.dealers-table {
  .active {
    background: #d9d9d9;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 12px 5px !important;
  }

  // ?
  /*td {
		text-align: center;
	}*/
}
</style>

<style lang="scss" scoped>
.ant-advanced-search-form {
  padding: 24px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  margin-bottom: 10px;
}

.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#advanced-search .ant-form {
  max-width: none;
}
#advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  padding: 20px 40px;
}
.search-result-text {
  margin: 20px 0 10px 0;
}
.buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 15px;
  > button {
    &:not(:first-child) {
      margin-left: 15px;
    }
  }
}
.leftButtons {
  margin-right: 20px;
}
.rightButtons {
  margin-left: 20px;
}
.filterFields {
  display: flex;
  flex-direction: row;
}
.filterFormItems {
  display: block;
  margin-right: 20px;
}
.selects {
  width: 200px;
}

.create-dealer {
  margin-bottom: 15px;
}
</style>
